import img1 from '../img/comparison/battery.png';
import img2 from '../img/comparison/grain.png';
import img3 from '../img/comparison/unit.png';
import img4 from '../img/comparison/data_setting.png';
import img5 from '../img/comparison/tap.png';
import img6 from '../img/comparison/touch.png';

const comparison = [
    {
        id: 1,
        img: img1,
        title: 'ПОТУЖНІСТЬ ВІД 10 ДО 300 КВТ',
        text: 'Широкий діапазон потужностей.'
    },
    {
        id: 2,
        img: img2,
        title: 'ТИП ПАЛИВА',
        text: 'гранули 6 - 8 мм, фракції 5-10 мм'
    },
    {
        id: 3,
        img: img3,
        title: 'ПОГОДНИЙ КОНТРОЛЕР',
        text: 'Регулювання в залежності від погодних умов.'
    },
    {
        id: 4,
        img: img4,
        title: 'КІМНАТНИЙ ТЕРМОСТАТ',
        text: 'Віддалене управління з будь-якої кімнати Вашого будинку.'
    },
    {
        id: 5,
        img: img5,
        title: 'NET CONTROL',
        text: 'Управління роботою через інтернет'
    },
    {
        id: 6,
        img: img6,
        title: 'ПАНЕЛЬ УПРАВЛІННЯ',
        text: 'Управління та контроль роботи пальника.'
    }
];

export default comparison;

import src from '../img/offer/decoration-element-1.png';

const offer_data = [
    {
        id: 1,
        img: src,
        title: 'ЯКІСНИЙ ТОВАР',
        text: 'Ви отримуєте продукт найвищої якості. В пальниках KIPI використовуються запатентовані технології.'
    },
    {
        id: 2,
        img: src,
        title: 'МОНТАЖ',
        text: 'Професійно, якісно та вчасно проводимо монтажні роботи.'
    },
    {
        id: 3,
        img: src,
        title: 'СЕРВІС',
        text: 'Сервісне та гарантійне обслуговування пальників.'
    },
];

export default offer_data;

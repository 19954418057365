import './offer.css';
import offer_data from "../../data/offer_data";

export default function Offer() {
    return (
        <div className='offer'>
            <p className='offer-p'>ЩО МИ ПРОПОНУЄМО?</p>
            <div className='offer-data'>
                {
                    offer_data.map(({img, id, title, text}) => (
                        <div key={id} className='offer-data-div'>
                            <img src={img} alt='img'/>
                            <h5 className='offer-title'>{title}</h5>
                            <p>{text}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

import "./button.css";

export default function Button({ user, value }) {

  return (
    <div>
      <button className="button" type="submit">
        {value}
      </button>
    </div>
  );
}

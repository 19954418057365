import './comparison.css';
import logo from '../../img/comparison/kipi_logo.png'
import comparison from "../../data/comparison";
import Line from "../../items/color-line/line";
import shoping from '../../img/comparison/outline_shopping_cart_black_24dp.png';

export default function Comparison() {
    return (
        <div className='comparison'>
            <div className='comparison-wrapper'>
                <div className='comparison-flex'>
                    <img src={logo} alt='logo' className='comparison-mainLogo'/>
                    {
                        comparison.map(({id, img, title, text}) => (
                            <div className='comparison-flex-wrapper' key={id}>
                                <div className='block-icon'>
                                    <div className='icon-inside'>
                                        <img src={img} alt='icon'/>
                                    </div>
                                </div>
                                <div className='comparison-flex-wrapper-inside'>
                                    <p>{title}</p>
                                    <p>{text}</p>
                                </div>
                            </div>
                        ))
                    }

                </div>
                <div className='comparison-flex'>
                    <p className='comparison-flex-p1'>ПОРІВНЯЛЬНИЙ РОЗРАХУНОК</p>
                    <p className='comparison-flex-p2'>ВИТРАТИ ПАЛИВА</p>
                    <p className='comparison-flex-p3'>Порівняння витрати палива пелетними пальниками KIPI потужністю
                        100кВт з іншими видами палива.</p>
                    <p className='comparison-flex-p3'>Розрахунок здійснювався за умов максимальних показників
                        навантаження на об’єм споживання палива, не враховуючи що ККД твердопаливного котла при роботі
                        на дровах складає 70%, а використовуючи пальник ми збільшуємо ККД котла до 95%.</p>
                    <div className='Line-wrapper'>
                        <Line color='#febd00' width='305px' text='Витрати електроенергії, кВт/год' number='100'/>
                        <Line color='#31708f' width='305px' text='Витрати газу, м.куб./год' number='11,95'/>
                        <Line color='#414141' width='305px' text='Витрати дизельного палива, л/год' number='10,40'/>
                        <Line color='#3c763d' width='305px' text='Витрати пелети, кг/год' number='20'/>
                    </div>

                    <p className='comparison-flex-p1'>ТЕРМІН ОКУПНОСТІ ПАЛЬНИКА</p>
                    <p className='comparison-flex-p1'>МАРКИ KIPI ROTARY 100</p>
                    <p className='comparison-flex-p3'>Розрахунок здійснювався за умов максимальних показників навантаження на об’єм споживання палива, для промислових споживачів.</p>

                    <div className='Line-wrapper'>
                        <Line color='#31708f' width='365px' text='Газ' number='3 місяці'/>
                        <Line color='#febd00' width='305px' text='Електроенергія' number='2 місяці'/>
                        <Line color='#414141' width='245px' text='Дизельне паливо' number='1 місяць'/>
                    </div>
                </div>
            </div>
            <div className='link-wrapper'>
                <a href='https://ukrpel.com.ua/uk/' target="_blank" rel="noreferrer"><img src={shoping} alt='icon'/>Детальніше в нашому
                    інтернет-магазині</a>
            </div>
        </div>
    )
}

import './contacts.css';
import Button from "../../items/button/button";
import img from '../../img/header/phone-icon.png';
import shoping from "../../img/comparison/outline_shopping_cart_black_24dp.png";

export default function Contacts() {
    return (
        <div className='contacts'>
            <div className='contacts-wrapper'>
                <div className='contacts-wrapper-inside'>
                    <p className='contacts-p1'>УКРАЇНА, М.ЛЬВІВ</p>
                    <p className='contacts-p1'>ВУЛ. ПРОМИСЛОВА 50/52</p>
                </div>

                <div className='contacts-wrapper-inside'>
                    <div className='contacts-row'>
                        <img src={img} alt='phone'/>
                        <a href='tel:+38(067)857-10-44'>+38(067)857-10-44</a>
                    </div>
                </div>
                <div className='contacts-wrapper-inside'>
                    <p className='contacts-p2'>Офіційний дистрибютор ТзОВ "Компанія РБР"</p>
                    <a href='https://ukrpel.com.ua/uk/' target="_blank" rel="noreferrer"><img src={shoping} alt='shoping'/>Детальніше в нашому
                        інтернет-магазині</a>
                </div>
            </div>
            <a href='tel:+38(067)857-10-44'><Button value='передзвоніть'/></a>
            <a href='https://ukrpel.com.ua/' target='_blank' rel="noreferrer" className='contacts-p3'>2022 | UKRPEL.COM.UA</a>
        </div>
    )
}

import "./main_page.css";
import Form from "../../items/form/form";
import service from "../../service/sendService";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

export default function MainPage() {
  const handleSubmit = async (values) => {
    const result = await service.send(values);
    if (result.status === 200) {
      toast.success("Запит відправлено!");
    }
  };

  return (
    <div className="main_page-main">
      <div className="main_page-wrapper">
        <div className="main_page-left">
          <p className="main_page-p1">
            ПЕЛЕТНІ ПАЛЬНИКИ KIPI - ЕФЕКТИВНА ЗАМІНА ГАЗУ
          </p>
          <p className="main_page-p2">ЕКОНОМІЯ З ПЕРШОГО ДНЯ</p>
        </div>
        <div className="main_page-right">
          <div className="form-wrapper">
            <p className="form-p">ОТРИМАЙТЕ БЕЗКОШТОВНУ</p>
            <p className="form-p">КОНСУЛЬТАЦІЮ</p>
            <p className="form-p-small">
              з підбору пальника, розрахунку потужності і витрат палива
            </p>
            <Form onSubmit={handleSubmit} />
          </div>
          <ToastContainer className="toastify" />
        </div>
      </div>
    </div>
  );
}

import './benefits.css';
import img from '../../img/benefits/img_bg3_1.png';
import benefits from "../../data/benefits";

export default function Benefits() {
    return (
        <div className='benefits'>
            <p className='benefits-p'>ПЕРЕВАГИ ПАЛЬНИКІВ <strong>KIPI</strong></p>
            <div className='benefits-wrapper'>
                <div className='benefits-flex'>
                    {
                        benefits.slice(0, 3).map(({id, img, title, text}) => (
                            <div className='benefits-flex-info' key={id}>
                                <img src={img} alt='logo'/>
                                <div className='benefits-flex-info-column'>
                                    <p className='benefits-flex-info-column-title'>{title}</p>
                                    <p className='benefits-flex-info-column-text'>{text}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <img src={img} alt='logo' className='benefits-center-img'/>

                <div className='benefits-flex'>
                    {
                        benefits.slice(3, 6).map(({id, img, title, text}) => (
                            <div className='benefits-flex-info' key={id}>
                                <img src={img} alt='logo'/>
                                <div className='benefits-flex-info-column'>
                                    <p className='benefits-flex-info-column-title'>{title}</p>
                                    <p className='benefits-flex-info-column-text'>{text}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>

            </div>
        </div>
    )
}

import './header.css';
import logo from '../../img/header/logo.png';
import phone from '../../img/header/phone-icon.png';
import Button from "../button/button";

export default function Header() {
    return (
        <div className='header-main'>
            <div className='header'>
                <img className='header-main-logo' alt='Kipi-logo' src={logo}/>
                <h2 className='header-left-p'>пелетні пальники kipi</h2>
            </div>
            <div className='header'>
                <img className='header-img-phone' alt='call-us' src={phone}/>
                <h2 className='header-right-p'><a href='tel:+38(067)857-10-44'>+38(067)857-10-44</a></h2>
                <a href='tel:+38(067)857-10-44'><div className='btn-ml-20'><Button value='передзвоніть'/></div></a>
            </div>
        </div>
    )
}

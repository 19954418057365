import "./call.css";
import Button from "../../items/button/button";

export default function Call() {
  return (
    <div className="call">
      <div className="call-wrapper">
        <p className="call-wrapper-p">ОТРИМАТИ КОНСУЛЬТАЦІЮ</p>
        <a href="tel:+38(067)857-10-44">
          <div className="btn-ml-20">
            <Button value="передзвоніть" />
          </div>
        </a>
      </div>
    </div>
  );
}

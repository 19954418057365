import img1 from '../img/usefull/home.png';
import img2 from '../img/usefull/location_city.png';
import img3 from '../img/usefull/business.png';


const usefull = [
    {
        id: 1,
        img: img1,
        title: 'ПРИВАТНІ ДОМОГОСПОДАРСТВА',
        text: 'Для обігріву приватних, або дачних будинків - альтернатива використання дров, вугілля або газу. Завдяки пелетним пальникам KIPI можливо модернізувати існуючий котел для інших твердих видів палива - модернізація насправді проста і без проблем.'
    },
    {
        id: 2,
        img: img2,
        title: 'ДЕРЖАВНІ УСТАНОВИ, ШКОЛИ ТА ГОТЕЛІ',
        text: 'Забезпечує екологічне та дешеве тепло у Вашому закладі. Дуже важливо, що при використанні пальників KIPI не потрібний додатковий персонал, пальники працюють без догляду і не потребують очищення.'
    },
    {
        id: 3,
        img: img3,
        title: 'ПРОМИСЛОВІСТЬ ТА ВИРОБНИЦТВО',
        text: 'Пальники KIPI можуть використовуватися не тільки для опалення, але і для пекарень, сушарень та інших галузей промисловості. Пальники KIPI дозволяють оптимізувати процеси опалення та сприяти економічним, організаційним та операційним перевагам.'
    },
];

export default usefull;

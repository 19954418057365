import './line.css';

export default function Line({color, width, text, number}) {
    return (
        <div className='line-wrapper'>
            <div style={{backgroundColor: color, width: width}} className='line'>{text}</div>
            <div className='number'>{number}</div>
        </div>
    )
}

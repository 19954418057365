import './App.css';
import Header from "./items/header/header";
import MainPage from "./components/main_page/Main_page";
import Offer from "./components/offer/offer";
import Benefits from "./components/benefits/benefits";
import Usefull from "./components/usefull/usefull";
import Comparison from "./components/comparison/comparison";
import Call from "./components/callMe/call";
import CallYou from "./components/help_you/call_you";
import Contacts from "./components/contacts/contacts";

function App() {
    return (
        <div className="App">
            <Header/>
            <MainPage/>
            <Offer/>
            <Benefits/>
            <Usefull/>
            <Comparison/>
            <Call/>
            <CallYou/>
            <Contacts/>
        </div>
    );
}

export default App;

import "./form.css";
import Button from "../button/button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

export default function FormComponent(props) {
  const { onSubmit } = props;

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Занадто коротке!")
      .max(50, "Занадто довге!")
      .required("Поле обов'язкове!"),
    phoneNumber: Yup.number().required("Поле обов'язкове!"),
    email: Yup.string().email("Невірний формат!").required("Поле обов'язкове!"),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        phoneNumber: "",
        email: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <Field
            autoComplete="off"
            type="text"
            id="name"
            label="name"
            name="name"
            className="input"
            placeholder="Ім'я"
          />
          {errors.name && touched.name ? (
            <div className="form-p-small-error">{errors.name}</div>
          ) : null}

          <Field
            autoComplete="off"
            type="text"
            id="email"
            label="email"
            name="email"
            className="input"
            placeholder="Email"
          />
          {errors.email && touched.email ? (
            <div className="form-p-small-error">{errors.email}</div>
          ) : null}

          <Field
            autoComplete="off"
            type="text"
            id="phoneNumber"
            label="phoneNumber"
            name="phoneNumber"
            className="input"
            placeholder="Мобільний номер"
          />
          {errors.phoneNumber && touched.phoneNumber ? (
            <div className="form-p-small-error">{errors.phoneNumber}</div>
          ) : null}
          <Button value="запитати" type="submit" className="form-btn" />
        </Form>
      )}
    </Formik>
  );
}

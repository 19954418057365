import './usefull.css';
import usefull from "../../data/usefull";

export default function Usefull() {
    return (
        <div className='usefull'>
            <p className='usefull-p'>ДЕ ВИКОРИСТОВУЮТЬСЯ ПАЛЬНИКИ <strong>KIPI</strong></p>
            <div className='usefull-wrapper'>
                {
                    usefull.map(({id, img, text, title}) => (
                        <div className='usefull-flex' key={id}>
                            <div className='usefull-circle'>
                                <img src={img} alt='img'/>
                            </div>
                            <p className='usefull-flex-p-title'>{title}</p>
                            <p>{text}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

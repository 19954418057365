import Form from "../../items/form/form";
import "./call_you.css";
import "../../items/form/form.css";
import "../../scss/call_you.scss";
import service from "../../service/sendService";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

export default function CallYou() {
  const handleSubmit = async (values) => {
    const result = await service.send(values);
    if (result.status === 200) {
      toast.success("Запит відправлено!");
    }
  };

  return (
    <div className="call_you">
      <p className="call_you-p">МИ ЗАВЖДИ РАДІ ВАМ ДОПОМОГТИ</p>
      <p className="call_you-p">
        Виберіть один з багатьох способів зв'язатися з нами. Ви можете замовити
        зворотній дзвінок, надіслати нам електронний лист.
        <br /> Виберіть те, що Вам найбільше підходить.
      </p>
      <div className="call_you-wrapper">
        <Form onSubmit={handleSubmit} />
        <ToastContainer className="toastify" />
      </div>
    </div>
  );
}

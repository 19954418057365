import img from '../img/benefits/point.png';

const benefits = [
    {
        id: 1,
        img: img,
        title: 'БЕЗПЕКА',
        text: 'Конструкція пальника мінімізує ризик відтоку полум\'я. Блок управління керує та сповіщує про небезпечні стани в роботі пальника.'
    },
    {
        id: 2,
        img: img,
        title: 'КОМФОРТНІСТЬ',
        text: 'Комфортне використання з можливістю дистанційного керування.'
    },
    {
        id: 3,
        img: img,
        title: 'ЕКОЛОГІЧНІСТЬ',
        text: 'Пелети є екологічно чистим паливом. Ви дбаєте про навколишнє середовище та про свою родину.'
    },
    {
        id: 4,
        img: img,
        title: 'ШВИДКИЙ МОНТАЖ',
        text: 'Завдяки модульній конструкції пальника (єдиний пальник на ринку), монтаж займає всього кілька годин.'
    },
    {
        id: 5,
        img: img,
        title: 'УНІВЕРСАЛЬНІСТЬ',
        text: 'Підходить до більшості сучасних котлів'
    },
    {
        id: 6,
        img: img,
        title: 'АВТОНОМНІСТЬ',
        text: 'Не залежить від газу. ПристосованІ для спалювання також низькоякісного палива та агропелетів.'
    }
];

export default benefits;
